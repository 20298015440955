.App {
  text-align: center;
  background: linear-gradient(180deg, #ECF3ED 0%, #D3F7DB 100%);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  

  min-height:100vh;
  /* height:100vh;
  min-height: -webkit-fill-available; */

  
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.fade::after  {
 
  
  animation: pulse 0.5s ease 6;
    
}

.sliderimg{
  max-width: 35vw;
  max-height: 32vh;
  object-fit: contain;
}




.alice-carousel__dots-item.__active{
  background: #1BC831 !important;
}

.alice-carousel__dots-item {
  background: #BFBFBF !important;
}

.button {
  border: none;
  border-radius: 1px;
  padding: 5px 20px;
  background: "#1BC831";
  position: relative;
  overflow: hidden;
}

.button:before {
  content: '';
  position: absolute;
  width: 100px;
  height: 100%;
  background-image: linear-gradient(
      120deg,
      rgba(255,255,255, 0) 30%,
      rgba(255,255,255, .8),
      rgba(255,255,255, 0) 70%
  );
  top: 0;
  left: -100px;
  animation: shine 4s infinite linear; /* Animation */
}
.hideSocialIcon{
  display: none;
}

.header-component {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 4.5rem;
}


@keyframes shine {
  0% {left: -100px}
  20% {left: 100%}
  100% {left: 100%}
}


/* .fadeIn {
  
  /* animation: pulse 0.5s ease 100; */
  /* animation: slide 0.5s forwards;
  animation-delay: 2s; */
    




@keyframes pulse{
  25%  {transform: scale(0.9);}
  75%  {transform: scale(1.1);}
}




/* @keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@-moz-keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@-webkit-keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@-o-keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@-ms-keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
} */
